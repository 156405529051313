import React from "react";
import "./MainBanner.scss";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Para, Title } from "../../../components/styledComponent";
import Button from "../../../components/generic/button";
import { Video } from "../hero-section/HeroSectionStyled";

const HeaderLogo = styled.img`
  max-width: 180px;
  width: 100%;
  min-width: 180px;
  margin-bottom: 14px;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    max-width: 110px;
    min-width: 110px;
  }
`;

const GradientEffect = styled.div`
  position: absolute;
  bottom: -60px;
  left: 0;
  content: "";
  background: linear-gradient(180deg, rgba(6, 0, 28, 0) 69.63%, #06001c 100%);
  width: 100%;
  z-index: 2;
  height: 220px;
  @media (max-width: 768px) {
    bottom: -2px;
  }
`;

function MainBanner() {
  return (
    <div className="heroBnner relative">
      <Video
        poster={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}connect-posterImg.webp`}
        cribVideo
        autoPlay
        loop
        muted
        playsInline
      >
        <source
          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}hero-video.mp4`}
          type="video/mp4"
        />
      </Video>
      {/* <img
        src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}landsale.png`}
        alt="heroBannerWeb"
      /> */}
      <div className="heroTitle">
        {/* <Title margin className="fade-animation">
          WELCOME TO THE VIRTUA
        </Title> */}
        <HeaderLogo src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}logo.svg`} />
        <div className="title-holder">
        <Para className="title fw-600">
        We Are Building, Stay Tuned.
        </Para>
          {/* <Para className="title">
            Virtua’s mission is to help game developers, studios and publishers
            increase the lifetime value of their players through our VGN
            platform.
          </Para>
          <Para className="sub-title ">
            Join us today and learn how <span className="fw-600">Virtua</span>{" "}
            can help grow the value of your game.
          </Para> */}
        </div>
        {/* <a href="#join-virtua" id="join_virtua">
          <Button border0 upper secondary>
            Join Virtua
          </Button>
        </a> */}
      </div>

      {/* <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}${isMobile ? "heroBannerWeb.webp":"heroBannerMob.webp"}`} /> */}
      <div className="arrow_holder">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
    </div>
  );
}

export default MainBanner;
