import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { Para } from "../styledComponent";

const NewsListingItem = ({ item, navigate }) => {
  return (
      <section
        className="news-list pointer"
        onClick={() => {
          navigate(`/news/${item.newsLink}`);
        }}
        key={item.heading}
      >
        <div className="news-img-holder">
          <img
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}${item.imgSrc}`}
            alt="news"
          />
        </div>
        <div className="col-right">
          <h3>{item.heading}</h3>
          <Para className="news_para">{item.subHeading}</Para>
          <div className="list-footer flex items-center space-between">
            <Para>
              {/* Author: <span>{item.authorName}</span> */}
              <address>{item.date}</address>
            </Para>
           
          </div>
        </div>
      </section>
  );
};

export default NewsListingItem;
