export const REACT_APP_VIDEO_ASSETS_URL = process.env.REACT_APP_VIDEO_ASSETS_URL
  ? process.env.REACT_APP_VIDEO_ASSETS_URL
  : "";

export const REACT_APP_S3_CLOUDFLARE_ASSETS_URL = process.env
  .REACT_APP_S3_CLOUDFLARE_ASSETS_URL
  ? process.env.REACT_APP_S3_CLOUDFLARE_ASSETS_URL
  : "";

export const REACT_APP_S3_CLOUDFLARE_IMAGE_URL = process.env
  .REACT_APP_S3_CLOUDFLARE_IMAGE_URL
  ? process.env.REACT_APP_S3_CLOUDFLARE_IMAGE_URL
  : "";
export const REACT_APP_S3_VOTE_IMAGE_URL = process.env
  .REACT_APP_S3_VOTE_IMAGE_URL
  ? process.env.REACT_APP_S3_VOTE_IMAGE_URL
  : "";

export const REACT_APP_META_TAG_ROBOTS = process.env.REACT_APP_META_TAG_ROBOTS;

export const REACT_APP_LEDGER_API = process.env.REACT_APP_LEDGER_API;

export const REACT_APP_VIRTUA_BACKEND_BASE_URL =
  process.env.REACT_APP_VIRTUABACKEND_BASE_URL;

export const SOCIAL_ICONS = [
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}facebook.svg`,
    href: "https://www.facebook.com/Virtuagamesnetwork/",
    alt: "facebook",
    text: "Facebook",
    id:"facebook"
  },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}discord.svg`,
    href: "https://discord.com/invite/virtuaofficial",
    alt: "discord",
    text: "Discord",
    id:"discord"
  },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}twitter.svg`,
    href: "https://twitter.com/Virtuametaverse",
    alt: "twitter",
    text: "Twitter",
    id:"twitter"
  },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}youtube.svg`,
    href: "https://www.youtube.com/VirtuaMetaverse",
    alt: "youtube",
    text: "Youtube",
    id:"youtube"
  },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}instagram.svg`,
    href: "https://www.instagram.com/Virtuametaverse/",
    alt: "instagram",
    text: "Instagram",
    id:"instagram"
  },
  // {
  //   src: reddit,
  //   href: "https://www.reddit.com/user/terravirtua",
  //   alt: "reddit",
  //   text:"Reddit"
  // },
  {
    src: `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}medium.svg`,
    href: "https://medium.com/terravirtua",
    alt: "medium",
    text: "Medium",
    id:"medium"
  },
  // {
  //   src: telegram,
  //   href: "https://t.me/Virtuametaverse",
  //   alt: "telegram",
  //   text:"Telegram"
  // },
];

export const showBodyOverflow = () => {
  document.body.classList.remove("overflow-hidden");
};
export const hideBodyOverflow = () => {
  document.body.classList.add("overflow-hidden");
};
export const addFilterClass = () => {
  document.body.classList.add("game-filter");
};
export const removeFilterClass = () => {
  document.body.classList.remove("game-filter");
};
export const REACT_APP_SITE_KEY = process.env.REACT_APP_SITE_KEY;

export const GeneralsIcon = {
  globeIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M5.50983 6.34987C5.82203 4.43637 6.54112 2.61104 7.62021 0.999397C7.67553 0.910333 7.6699 0.795953 7.60427 0.713453C7.53959 0.630012 7.4299 0.597199 7.32959 0.630012C3.95269 1.81037 1.36037 4.55917 0.379705 7.99991C0.358142 8.09647 0.39658 8.19773 0.476269 8.25585C0.556897 8.31398 0.663775 8.3196 0.750021 8.26991C2.15445 7.42521 3.70427 6.84955 5.31951 6.57018C5.42358 6.54487 5.50046 6.45581 5.50983 6.34987Z"
        fill="black"
      />
      <path
        d="M0.379725 14.0001C1.36414 17.4315 3.95079 20.1709 7.32025 21.3503C7.41587 21.3812 7.52088 21.3512 7.58651 21.2753C7.65214 21.1984 7.66526 21.0906 7.62026 21.0006C6.54116 19.389 5.82208 17.5637 5.50988 15.6501C5.48925 15.5554 5.41519 15.4814 5.31956 15.4598C3.70419 15.1804 2.15435 14.6048 0.750067 13.7601C0.669439 13.7151 0.570062 13.7188 0.493188 13.7694C0.415373 13.8191 0.372224 13.9082 0.379725 14.0001Z"
        fill="black"
      />
      <path
        d="M21.52 7.99992C20.5365 4.56855 17.9489 1.82911 14.5804 0.649711C14.4801 0.616898 14.3704 0.650649 14.3058 0.733152C14.2401 0.815655 14.2345 0.930032 14.2898 1.02003C15.3689 2.63163 16.088 4.45597 16.4002 6.36955C16.4208 6.46518 16.4949 6.53924 16.5905 6.55987C18.2059 6.83926 19.7557 7.41583 21.16 8.25959C21.2435 8.30178 21.3438 8.29428 21.4197 8.23897C21.4956 8.18459 21.5341 8.09274 21.52 7.99992Z"
        fill="black"
      />
      <path
        d="M16.3898 15.6501C16.0776 17.5636 15.3586 19.389 14.2804 21.0006C14.2241 21.0897 14.2307 21.204 14.2954 21.2865C14.3601 21.37 14.4698 21.4028 14.5701 21.37C17.947 20.1896 20.5403 17.4408 21.52 14.0001C21.5415 13.9035 21.504 13.8023 21.4234 13.7441C21.3437 13.686 21.2359 13.6804 21.1506 13.7301C19.7452 14.5748 18.1956 15.1504 16.5801 15.4298C16.4761 15.4551 16.4002 15.5442 16.3898 15.6501Z"
        fill="black"
      />
      <path
        d="M6.88987 15.7298C8.08616 15.8967 9.29186 15.9867 10.5003 15.9998V22L10.2303 21.9297L9.91996 21.7994V21.8003C9.0518 21.2875 8.35617 20.529 7.92022 19.6206L7.71021 19.2202C7.6399 19.0805 7.5799 18.9305 7.51052 18.7805L7.33051 18.3408L7.32958 18.3399C7.03425 17.5796 6.79611 16.7967 6.61988 15.9998V15.9398C6.61988 15.8798 6.61988 15.7401 6.54956 15.6398L6.88987 15.7298Z"
        fill="black"
      />
      <path
        d="M15.3605 15.6801C15.3605 15.7805 15.3605 15.8798 15.2901 15.9802C15.2198 16.0805 15.2901 15.9802 15.2901 16.0402C15.1186 16.8024 14.8907 17.5515 14.6104 18.2799L14.4304 18.7196C14.3601 18.8696 14.3001 19.0196 14.2307 19.1593L14.0207 19.5596L14.0198 19.5606C13.5838 20.469 12.8882 21.2275 12.0201 21.7403L11.7097 21.8706C11.6535 21.8791 11.5963 21.8791 11.54 21.8706V15.9998C12.6979 15.9801 13.8529 15.8892 15.0005 15.7298L15.3605 15.6801Z"
        fill="black"
      />
      <path
        d="M10.2303 0.120003L10.5003 0V6.00017C9.78027 6.00017 9.06025 6.00017 8.35055 6.10049L7.70085 6.16049L6.89083 6.26081L6.54114 6.3105C6.54114 6.21018 6.54114 6.1108 6.61145 6.01049C6.68177 5.91017 6.61145 6.01049 6.61145 5.95049L6.60958 5.94955C6.7849 5.19108 7.01552 4.44576 7.2996 3.7201L7.4796 3.28041C7.54992 3.1304 7.60992 2.9804 7.6793 2.84071L7.8893 2.44038L7.89024 2.43944C8.14993 1.96037 8.46213 1.51129 8.82027 1.09973C8.90183 1.00785 8.98808 0.921595 9.07995 0.840038C9.3134 0.604718 9.58342 0.408769 9.87966 0.259701L10.19 0.129384L10.2303 0.120003Z"
        fill="black"
      />
      <path
        d="M15.3605 6.31988L15.0005 6.27019L14.1904 6.16988L13.5408 6.10988C12.8704 6.00019 12.1898 6.00019 11.4998 6.00019V0.0796937C11.556 0.0703186 11.6141 0.0703186 11.6704 0.0796937L11.9807 0.21001H11.9798C12.276 0.35814 12.5461 0.554084 12.7804 0.789411L13.0401 1.0491V1.05004C13.4114 1.47566 13.7339 1.94161 14.0001 2.43946L14.2101 2.83979C14.2805 2.97948 14.3405 3.12948 14.4098 3.27948L14.5898 3.71918V3.72012C14.8767 4.46169 15.1073 5.2239 15.2799 6.00018V6.06018C15.2799 6.12019 15.3399 6.21957 15.3605 6.31988Z"
        fill="black"
      />
      <path
        d="M10.2303 0.120003L10.5003 0V6.00017C9.78032 6.00017 9.0603 6.00017 8.3506 6.10049L7.7009 6.16049L6.89088 6.26081L6.88994 6.25987C6.81775 6.2655 6.7465 6.24018 6.69306 6.19143C6.63962 6.14174 6.60962 6.07236 6.60962 6.00017V5.94017C6.78588 5.18453 7.0165 4.442 7.29964 3.72011L7.47964 3.28041C7.54996 3.13041 7.60996 2.98041 7.67934 2.84072L7.88934 2.44039L7.89028 2.43945C8.14997 1.96037 8.46217 1.5113 8.82031 1.09973C8.90187 1.00786 8.98813 0.921602 9.08 0.840045C9.31344 0.604726 9.58346 0.408777 9.8797 0.259708L10.19 0.129392L10.2303 0.120003Z"
        fill="black"
      />
      <path
        d="M15.2902 6.00019C15.2874 6.0705 15.2564 6.13706 15.2039 6.18488C15.1505 6.23175 15.0811 6.25519 15.0099 6.24957L14.1998 6.14925L13.5501 6.08925C12.8704 6.00019 12.1898 6.00019 11.4998 6.00019V0.0796937C11.556 0.0703186 11.6141 0.0703186 11.6704 0.0796937L11.9807 0.21001H11.9798C12.276 0.35814 12.546 0.554084 12.7804 0.789411L13.0401 1.0491V1.05004C13.4114 1.47566 13.7339 1.94161 14.0001 2.43946L14.2101 2.83979C14.2805 2.97948 14.3405 3.12948 14.4098 3.27949L14.5898 3.71918V3.72012C14.8767 4.46169 15.1073 5.2239 15.2799 6.00018L15.2902 6.00019Z"
        fill="black"
      />
      <path
        d="M6.88987 15.7298C8.08616 15.8967 9.29186 15.9867 10.5003 15.9998V22L10.2303 21.9297L9.91995 21.7994V21.8003C9.0518 21.2875 8.35616 20.529 7.92022 19.6206L7.71021 19.2202C7.63989 19.0805 7.57989 18.9305 7.51052 18.7805L7.33051 18.3409L7.32957 18.3399C7.03424 17.5796 6.79611 16.7967 6.61987 15.9998V15.9398C6.64425 15.8123 6.75955 15.7223 6.88987 15.7298Z"
        fill="black"
      />
      <path
        d="M15.2901 15.9998V16.0598C15.1186 16.822 14.8907 17.5711 14.6104 18.3005L14.4304 18.7402C14.3601 18.8902 14.3001 19.0402 14.2307 19.1799L14.0207 19.5802H14.0198C13.5838 20.4887 12.8882 21.2481 12.0201 21.76L11.7097 21.8903C11.6535 21.8997 11.5963 21.8997 11.54 21.8903V15.9998C12.6979 15.9801 13.8529 15.8892 15.0005 15.7298C15.0755 15.7213 15.1514 15.7457 15.2077 15.7982C15.263 15.8498 15.2939 15.9239 15.2901 15.9998Z"
        fill="black"
      />
      <path
        d="M4.99983 10.5003H0L0.0600017 10.3503C0.120942 10.2191 0.205319 10.1 0.309393 9.99971C0.999413 9.0003 2.79947 8.13027 5.17985 7.59964V7.91933V7.99996V8.44997C5.17985 8.59997 5.17985 8.89998 5.11985 9.12967C5.05985 9.35936 5.11985 9.34999 5.11985 9.47V10.2303L4.99983 10.5003Z"
        fill="black"
      />
      <path
        d="M5.16952 14.3498C2.79946 13.8698 0.999403 12.9997 0.309383 12.0003C0.219381 11.8963 0.149066 11.7781 0.0993772 11.6497L0 11.4997H4.99983C4.99983 11.9694 4.99983 12.4297 5.05983 12.8694C5.05983 13.0991 5.05983 13.3297 5.11983 13.5491C5.17983 13.7694 5.11983 13.8491 5.11983 13.9991C5.13952 14.1398 5.16015 14.2401 5.16952 14.3498Z"
        fill="black"
      />
      <path
        d="M16.8202 8.41995C16.8202 8.13962 16.7602 7.86962 16.7198 7.59961L17.0095 7.66992H17.0695L17.2899 7.72993L17.8402 7.87993C18.1505 7.96993 18.4402 8.05994 18.7102 8.16026C18.833 8.1987 18.953 8.24557 19.0702 8.29995L19.5006 8.47995C19.6374 8.53527 19.7706 8.59902 19.9 8.67027L20.2703 8.86997L20.6106 9.07997C20.7156 9.1456 20.8159 9.21872 20.9106 9.30029C21.0044 9.36873 21.0944 9.44186 21.1806 9.51967C21.2603 9.59936 21.341 9.66968 21.4103 9.74937V9.7503C21.6081 9.96406 21.7619 10.2153 21.8603 10.49H16.9402C16.9402 10.0297 16.9402 9.5703 16.8802 9.12965C16.8699 8.89995 16.8399 8.64964 16.8202 8.41995Z"
        fill="black"
      />
      <path
        d="M16.9402 11.4997H21.8603C21.7272 11.8878 21.4919 12.2338 21.1806 12.5C21.0944 12.5779 21.0044 12.6519 20.9106 12.7204C20.8159 12.801 20.7156 12.8751 20.6106 12.9397L20.2703 13.1498L19.8999 13.3494V13.3504C19.7706 13.4216 19.6374 13.4844 19.5005 13.5398L19.0702 13.7198C18.953 13.7741 18.833 13.821 18.7102 13.8604C18.4402 13.9607 18.1505 14.0507 17.8402 14.1407L17.2898 14.2907L17.0695 14.3507L17.0001 14.3498L16.7104 14.4201C16.7104 14.1501 16.7808 13.8801 16.8108 13.5998C16.8408 13.3194 16.8108 13.1197 16.8708 12.8797C16.9205 12.4203 16.9402 12.0003 16.9402 11.4997Z"
        fill="black"
      />
      <path
        d="M10.2303 0.120003L10.5003 0V6.00017C9.78032 6.00017 9.0603 6.00017 8.3506 6.10049L7.7009 6.16049L6.89088 6.26081L6.88994 6.25987C6.81775 6.2655 6.7465 6.24018 6.69306 6.19143C6.63962 6.14174 6.60962 6.07236 6.60962 6.00017V5.94017C6.78588 5.18453 7.0165 4.442 7.29964 3.72011L7.47964 3.28041C7.54996 3.13041 7.60996 2.98041 7.67934 2.84072L7.88934 2.44039L7.89028 2.43945C8.14997 1.96037 8.46217 1.5113 8.82031 1.09973C8.90187 1.00786 8.98813 0.921602 9.08 0.840045C9.31344 0.604726 9.58346 0.408777 9.8797 0.259708L10.19 0.129392L10.2303 0.120003Z"
        fill="black"
      />
      <path
        d="M15.2902 6.00019C15.2874 6.0705 15.2564 6.13706 15.2039 6.18488C15.1505 6.23175 15.0811 6.25519 15.0099 6.24957L14.1998 6.14925L13.5501 6.08925C12.8704 6.00019 12.1898 6.00019 11.4998 6.00019V0.0796937C11.556 0.0703186 11.6141 0.0703186 11.6704 0.0796937L11.9807 0.21001H11.9798C12.276 0.35814 12.546 0.554084 12.7804 0.789411L13.0401 1.0491V1.05004C13.4114 1.47566 13.7339 1.94161 14.0001 2.43946L14.2101 2.83979C14.2805 2.97948 14.3405 3.12948 14.4098 3.27949L14.5898 3.71918V3.72012C14.8767 4.46169 15.1073 5.2239 15.2799 6.00018L15.2902 6.00019Z"
        fill="black"
      />
      <path
        d="M6.88987 15.7298C8.08616 15.8967 9.29186 15.9867 10.5003 15.9998V22L10.2303 21.9297L9.91995 21.7994V21.8003C9.0518 21.2875 8.35616 20.529 7.92022 19.6206L7.71021 19.2202C7.63989 19.0805 7.57989 18.9305 7.51052 18.7805L7.33051 18.3409L7.32957 18.3399C7.03424 17.5796 6.79611 16.7967 6.61987 15.9998V15.9398C6.64425 15.8123 6.75955 15.7223 6.88987 15.7298Z"
        fill="black"
      />
      <path
        d="M15.2901 15.9998V16.0598C15.1186 16.822 14.8907 17.5711 14.6104 18.3005L14.4304 18.7402C14.3601 18.8902 14.3001 19.0402 14.2307 19.1799L14.0207 19.5802H14.0198C13.5838 20.4887 12.8882 21.2481 12.0201 21.76L11.7097 21.8903C11.6535 21.8997 11.5963 21.8997 11.54 21.8903V15.9998C12.6979 15.9801 13.8529 15.8892 15.0005 15.7298C15.0755 15.7213 15.1514 15.7457 15.2077 15.7982C15.263 15.8498 15.2939 15.9239 15.2901 15.9998Z"
        fill="black"
      />
      <path
        d="M10.5003 6.99957V10.5003H6.00012C6.00856 9.53561 6.08544 8.57371 6.22982 7.62023C6.24575 7.51148 6.33107 7.42616 6.43982 7.41022C7.78237 7.18147 9.13893 7.04457 10.5003 6.99957Z"
        fill="black"
      />
      <path
        d="M6.00023 11.4997H10.5004V15.0004C9.12594 14.9639 7.75613 14.8336 6.39961 14.6104C6.29179 14.5935 6.20648 14.5082 6.1896 14.4004C6.05835 13.4395 5.99461 12.47 6.00023 11.4997Z"
        fill="black"
      />
      <path
        d="M11.4997 11.4997H15.9398C15.9267 12.5047 15.8395 13.5079 15.6801 14.4998C15.667 14.616 15.5761 14.707 15.4598 14.7201C14.1332 14.8898 12.7972 14.9732 11.4603 14.9704L11.4997 11.4997Z"
        fill="black"
      />
      <path
        d="M15.9399 10.5003H11.4998V6.99955C12.8376 6.99674 14.1737 7.08018 15.5002 7.24988C15.6155 7.263 15.7074 7.35394 15.7205 7.4702C15.8677 8.47335 15.9408 9.48588 15.9399 10.5003Z"
        fill="black"
      />
      <path
        d="M5.07954 13.5501V14.0001C5.07579 14.0751 5.03922 14.1445 4.97922 14.1904C4.92016 14.2364 4.84328 14.2551 4.77015 14.2401C2.62977 13.7301 0.999404 12.9304 0.309383 12.0004C0.232507 11.8879 0.162192 11.7707 0.0993773 11.6497L0 11.4997H4.99983C4.99983 11.9694 4.99983 12.4298 5.05983 12.8694C4.99983 13.1001 4.99985 13.3298 5.07954 13.5501Z"
        fill="black"
      />
      <path
        d="M5.13015 7.99993V8.44995C5.13015 8.59995 5.13015 8.89996 5.07015 9.12965C5.01015 9.35933 5.07015 9.34996 5.07015 9.46998V10.2303V10.5003H0L0.0600017 10.3503C0.135004 10.2275 0.218445 10.1113 0.309393 9.99969C0.999413 9.06967 2.62978 8.26996 4.81984 7.72995C4.89859 7.71401 4.98016 7.73651 5.0411 7.78901C5.10204 7.84151 5.13484 7.9193 5.13015 7.99993Z"
        fill="black"
      />
      <path
        d="M16.8202 8.41994V7.95025C16.8005 7.82462 16.8774 7.70369 17.0002 7.66992H17.0602L17.2805 7.72992L17.8309 7.87993C18.1412 7.96993 18.4309 8.05993 18.7009 8.16026H18.7C18.8228 8.1987 18.9437 8.24557 19.06 8.29995L19.4903 8.47995C19.6272 8.53527 19.7612 8.59902 19.8906 8.67027L20.261 8.86996L20.6013 9.07997H20.6004C20.7063 9.1456 20.8066 9.21872 20.9004 9.30029C20.9941 9.36873 21.0841 9.44186 21.1704 9.51967C21.2501 9.59936 21.3307 9.66967 21.4001 9.74936V9.7503C21.5988 9.96406 21.7516 10.2153 21.8501 10.49H16.9403C16.9403 10.0297 16.9403 9.5703 16.8803 9.12964C16.8671 8.88964 16.8465 8.65337 16.8202 8.41994Z"
        fill="black"
      />
      <path
        d="M16.9401 11.4997H21.8603C21.7271 11.8878 21.4918 12.2338 21.1806 12.5C21.0943 12.5779 21.0043 12.6519 20.9106 12.7204C20.8159 12.801 20.7156 12.8751 20.6106 12.9397L20.2702 13.1498L19.8999 13.3494V13.3504C19.7705 13.4216 19.6374 13.4844 19.5005 13.5398L19.0702 13.7198C18.953 13.7741 18.833 13.821 18.7102 13.8604C18.4402 13.9607 18.1505 14.0507 17.8401 14.1407L17.2898 14.2907L17.0695 14.3507L17.0001 14.3498C16.9298 14.3479 16.8642 14.316 16.8182 14.2626C16.7732 14.2101 16.7517 14.1398 16.7601 14.0704V13.6007C16.7601 13.4404 16.7601 13.131 16.8201 12.8807C16.9204 12.4204 16.9401 12.0003 16.9401 11.4997Z"
        fill="black"
      />
    </svg>
  ),
  pcIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
    >
      <path
        d="M24.7739 2.8916H18.8871C18.5618 2.8916 18.2498 3.01854 18.0199 3.24448C17.7899 3.47043 17.6606 3.77688 17.6606 4.09642V18.5543C17.6606 18.8738 17.7899 19.1802 18.0199 19.4062C18.2498 19.6321 18.5618 19.7591 18.8871 19.7591H24.7739C25.0991 19.7591 25.4111 19.6321 25.6411 19.4062C25.8711 19.1802 26.0003 18.8738 26.0003 18.5543V4.09642C26.0003 3.77688 25.8711 3.47043 25.6411 3.24448C25.4111 3.01854 25.0991 2.8916 24.7739 2.8916ZM21.8305 18.3133C21.4909 18.3133 21.1589 18.2144 20.8766 18.029C20.5942 17.8437 20.3741 17.5802 20.2442 17.272C20.1142 16.9638 20.0802 16.6247 20.1465 16.2975C20.2127 15.9703 20.3762 15.6697 20.6164 15.4338C20.8565 15.1979 21.1624 15.0373 21.4955 14.9722C21.8286 14.9071 22.1738 14.9405 22.4875 15.0682C22.8013 15.1959 23.0694 15.4121 23.2581 15.6894C23.4467 15.9668 23.5474 16.2929 23.5474 16.6265C23.5449 17.0731 23.3631 17.5007 23.0417 17.8165C22.7203 18.1322 22.285 18.3108 21.8305 18.3133ZM23.7927 9.63859H19.8682C19.673 9.63859 19.4859 9.56243 19.3479 9.42686C19.2099 9.29129 19.1323 9.10742 19.1323 8.9157C19.1323 8.72398 19.2099 8.54011 19.3479 8.40454C19.4859 8.26897 19.673 8.19281 19.8682 8.19281H23.7927C23.9879 8.19281 24.175 8.26897 24.313 8.40454C24.451 8.54011 24.5286 8.72398 24.5286 8.9157C24.5286 9.10742 24.451 9.29129 24.313 9.42686C24.175 9.56243 23.9879 9.63859 23.7927 9.63859ZM23.7927 6.74702H19.8682C19.673 6.74702 19.4859 6.67086 19.3479 6.53529C19.2099 6.39972 19.1323 6.21585 19.1323 6.02413C19.1323 5.83241 19.2099 5.64854 19.3479 5.51297C19.4859 5.3774 19.673 5.30124 19.8682 5.30124H23.7927C23.9879 5.30124 24.175 5.3774 24.313 5.51297C24.451 5.64854 24.5286 5.83241 24.5286 6.02413C24.5286 6.21585 24.451 6.39972 24.313 6.53529C24.175 6.67086 23.9879 6.74702 23.7927 6.74702Z"
        fill="black"
      />
      <path
        d="M13.9812 18.5542H7.11329C6.91813 18.5542 6.73097 18.6304 6.59297 18.7659C6.45497 18.9015 6.37744 19.0854 6.37744 19.2771C6.37744 19.4688 6.45497 19.6527 6.59297 19.7883C6.73097 19.9238 6.91813 20 7.11329 20H13.9812C14.1764 20 14.3635 19.9238 14.5015 19.7883C14.6395 19.6527 14.7171 19.4688 14.7171 19.2771C14.7171 19.0854 14.6395 18.9015 14.5015 18.7659C14.3635 18.6304 14.1764 18.5542 13.9812 18.5542Z"
        fill="black"
      />
      <path
        d="M21.0943 1.44578V1.20482C21.0943 0.885281 20.9651 0.578831 20.7351 0.352883C20.5051 0.126936 20.1932 0 19.8679 0H1.22642C0.90115 0 0.589206 0.126936 0.359209 0.352883C0.129211 0.578831 0 0.885281 0 1.20482V12.0482H16.1887V4.09639C16.1887 3.3934 16.4729 2.71921 16.9789 2.22213C17.4849 1.72504 18.1712 1.44578 18.8868 1.44578H21.0943Z"
        fill="black"
      />
      <path
        d="M0 14.6988C0 15.0183 0.129211 15.3248 0.359209 15.5507C0.589206 15.7767 0.90115 15.9036 1.22642 15.9036H16.1887V13.494H0V14.6988Z"
        fill="black"
      />
    </svg>
  ),
};
