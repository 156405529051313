import React, { useEffect } from "react";
import "./VirtualWorld.scss";
import { Helmet } from "react-helmet";
import VirtuaSdk from "./VirtuaSdk";
import GamerLounge from "./gamerLounge";
import Marketplace from "./Marketplace";

function ProductPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Virtua | Enhance Player Value with Gamers Lounge & Virtua SDK
        </title>
        <meta name="description" content="Discover how Virtua empowers game developers, studios, and publishers to enhance player lifetime value through the VGN Platform and Virtua SDK. Explore the Gamers Lounge App, NFT Marketplace, and SDK to create engaging experiences and grow your game" />
      </Helmet>
      <VirtuaSdk />
      {/* <GamerLounge /> */}
      <Marketplace />
    </>
  );
}

export default ProductPage;
