import React, { useEffect } from "react";
import "./VirtualWorld.scss";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import styled from "styled-components";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

function VirtuaSdk() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="virtual-wrold">
      <div className="banner">
        <GradientEffect />
        <img
          className="slide-bg web"
          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtua-sdk.png`}
          alt="virtua-sdk"
        />
        <img
          className="slide-bg mob"
          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mob-sdk.jpg`}
          alt="virtua-sdk"
        />
        <BannerText className="text-holder one">
          <SectionHeading>Virtua SDK</SectionHeading>
          <Para className="sdk">
            Our SDK is a powerful tool that is easy to implement. Our expert
            team will help you set it up to offer the best experience to your
            players.
          </Para>
          <Para className="purple">Features</Para>
          <ul className="flex items-center flex-wrap flex-text-center">
            <li>Friends</li>
            <li>Weekly leaderboard</li>
            <li>Quests</li>
            <li>Shop</li>
          </ul>
        </BannerText>
      </div>
    </div>
  );
}

export default VirtuaSdk;
