import "./VirtualWorld.scss";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import styled from "styled-components";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

function GamerLounge() {
  return (
    <>
      <div className="virtual-wrold">
        <div className="banner">
          <GradientEffect />
          <img
            className="slide-bg web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gamer-lounge.png`}
            alt="gamer-lounge"
          />
          <img
            className="slide-bg mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mob-gamer-lounge.jpg`}
            alt="gamer-lounge"
          />
          <BannerText className="text-holder one">
            <SectionHeading>Gamers Lounge Mobile App</SectionHeading>
            <Para className="description">
              The space where it all comes together. This is a central hub that
              allows players to go on quests to play your games, hang out with
              their friends and customize their personal space with trophies and
              collectibles from your games.
            </Para>
            <div className="buttons w-100">
              <a
              id="gamers_lounge"
                href="https://play.google.com/store/apps/details?id=com.virtua.gamers.lounge"
                target="_blank"
                rel="noreferrer"
                className="btn"
              >
                <span className="icon">
                  <img
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}android-dark.svg`}
                    alt="android"
                  />
                </span>
                Google Play
              </a>
            </div>
            <Para className="purple">Features</Para>
            <ul className="flex items-center">
              <li>Invite Friends</li>
              <li>Play games</li>
              <li>Customization</li>
            </ul>
          </BannerText>
        </div>
        {/* <DashboardBanner handleClickGamersLounge={handleClickGamersLounge} /> */}
      </div>
    </>
  );
}

export default GamerLounge;
