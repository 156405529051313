import "./VirtuaSdk.scss";
import React from "react";
import {
  CardTitle,
  Para,
  SectionHeading,
  SectionTitle,
} from "../../../components/styledComponent";

const VirtuaSdk = () => {
  return (
    <>
      <section className="sdk-section">
        <div className="main-content">
          <SectionHeading>VIrtua SDK</SectionHeading>
          <Para>
            To achieve this, Virtua offers a powerful SDK with various levels of
            integration, enabling you to tailor the experience for your players.
          </Para>
        </div>
        <div className="cardlisting">
          <div className="d-grid">
            <div className="custom-col-width">
              <div className="card">
                <SectionTitle>
                  Basic
                  <br />
                  Integration
                </SectionTitle>
                <CardTitle>Shared Login</CardTitle>
                <Para cardWeight sdkCardDetail>
                  Centralized login system for players.
                </Para>
                <Para cardWeight sdkCardDetail className="mb-30">
                  Single sign-on (SSO) across all games and services.
                </Para>
                <CardTitle>Quests</CardTitle>
                <Para cardWeight sdkCardDetail>
                  Basic quest management.
                </Para>
                <Para cardWeight sdkCardDetail>
                  Ability to create, manage, and track quests.
                </Para>
              </div>
            </div>
            <div className="custom-col-width">
              <div className="card dark">
                <SectionTitle>
                  Advanced
                  <br />
                  Integration
                </SectionTitle>
                {/* <Para className="mb-30">Comprehensive features for a full-fledged ecosystem:</Para> */}
                <CardTitle>Game Assets</CardTitle>
                <Para cardWeight sdkCardDetail>
                  Management of in-game assets.
                </Para>
                <Para cardWeight sdkCardDetail>
                  Support for asset creation, trading, and customization.
                </Para>
                {/* <Para cardWeight sdkCardDetail className="mb-44">
                  Customizable social hubs and player interactions.
                </Para> */}
                <CardTitle>Tournaments</CardTitle>
                <Para cardWeight sdkCardDetail>
                  Setup and manage tournaments.
                </Para>
                <Para sdkCardDetail>
                  Support for different tournament formats, scheduling and
                  rewards.
                </Para>
                <CardTitle>Progression rewards</CardTitle>
                <Para cardWeight sdkCardDetail>
                  Drive engagement through game rewards exclusive to the
                  platform.
                </Para>
                <Para cardWeight sdkCardDetail>
                  Greater discoverability through shared game reward points
                </Para>
              </div>
            </div>
            <div className="custom-col-width">
              <div className="card">
                <SectionTitle>
                  Intermediate
                  <br />
                  Integration
                </SectionTitle>
                <CardTitle>Marketplace</CardTitle>
                <Para cardWeight sdkCardDetail>
                  In-game marketplace for buying and selling
                </Para>
                <Para cardWeight sdkCardDetail>
                  Integration with virtual currencies and real money
                  transactions.
                </Para>
                <CardTitle>Friends list</CardTitle>
                <Para cardWeight sdkCardDetail>
                  Add & chat with friends across games.
                </Para>
                <Para cardWeight sdkCardDetail>
                  Game-specific leaderboards amongst friends to drive social
                  engagement.
                </Para>
                {/* <Para cardWeight sdkCardDetail className="mb-44">
                  Real-time updates and ranking.
                </Para> */}
                <CardTitle>Daily login bonuses</CardTitle>
                <Para cardWeight sdkCardDetail>
                  Drive retention through daily rewards.
                </Para>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VirtuaSdk;
