import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import styled from "styled-components";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

function SoccerGame() {
  return (
    <>
      <div className="virtual-wrold-game">
        <div className="banner">
          <GradientEffect />
          <img
            className="slide-bg web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}soccer-img.png`}
            alt="soccer"
          />
          <img
            className="slide-bg mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mob-soccer.jpg`}
            alt="soccer"
          />
          <BannerText className="text-holder left">
            <SectionHeading>Soccer Star To﻿p Leagues</SectionHeading>
            <address className="developer">Developer: Redvel Games</address>
            <Para className="description">
              Soccer Star Top Leagues is a mobile game developed by Redvel
              Games. The game is all about playing football and managing your
              player. Leaning into this, the teams worked together to focus on a
              set of features that would enhance the experience that players
              already love. Stay tuned for the results!
            </Para>
            {/* <div className="buttons w-100">
              <a
                href="https://play.google.com/store/apps/details?id=com.generagames.soccerstarleaguesfootball"
                target="_blank"
                id="soccer_star"
                rel="noreferrer"
                className="btn"
              >
                <span className="icon">
                  <img
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}android-dark.svg`}
                    alt="android"
                  />
                </span>
                Google Play
              </a>
            </div> */}
            <Para className="purple">Key features:</Para>
            <ul className="flex items-center flex-wrap">
              <li>Shared login</li>
              <li>In-game tournament</li>
              <li>Quests</li>
              <li>Weekly leaderboard</li>
              <li>In-game avatar outfits</li>
            </ul>
          </BannerText>
        </div>
        {/* <DashboardBanner handleClickGamersLounge={handleClickGamersLounge} /> */}
      </div>
    </>
  );
}

export default SoccerGame;
