import React, { useEffect } from "react";
import "./VirtualWorld.scss";
import {
  GeneralsIcon,
  REACT_APP_S3_CLOUDFLARE_IMAGE_URL,
} from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import styled from "styled-components";
import { MARKETPLACE_URL } from "../../enviroment";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

function Marketplace() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="virtual-wrold">
      <div className="banner">
        <GradientEffect />
        <img
          className="slide-bg web"
          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}marketplace.png`}
          alt="marketplace"
        />
        <img
          className="slide-bg mob"
          src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mob-marketplace.jpg`}
          alt="marketplace"
        />
        <BannerText className="text-holder one">
          <SectionHeading>E-commerce</SectionHeading>
          <Para className="description market_place">
            Expand your economy through our digital marketplace. Offer exclusive
            and limited items to players that they can collect, sell and trade
            with other players.
          </Para>
          <div className="buttons w-100">
            <a
              href={MARKETPLACE_URL}
              target="_blank"
              id="marketplace"
              rel="noreferrer"
              className="btn"
            >
              <span className="icon" style={{ lineHeight: "0" }}>
                {GeneralsIcon.globeIcon}
                {/* <img
                  src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}browser.svg`}
                  alt="browse"
                /> */}
              </span>
              Visit website
            </a>
          </div>
          <Para className="purple">Features</Para>
            <ul className="flex items-center">
              <li>Buying</li>
              <li>Selling</li>
              <li>Trading</li>
            </ul>
        </BannerText>
      </div>
    </div>
  );
}

export default Marketplace;
