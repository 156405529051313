import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import "./newsDetail.scss";
import { getEndPath, processedContent } from "../../utils/helpers/functions";
import axios from "axios";
import { NEWS_API_URL } from "../../enviroment";
import ComponentNotFound from "../component-not-found/ComponentNotFound";
import Loader from "../../components/core/loader/loader";

function NewsDetail() {
  const [newsDetail, setNewsDetail] = useState(null);
  const [loading, setLoading] = useState("true");
  const GradientEffect = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    background: linear-gradient(
      180deg,
      rgba(12, 3, 26, 0) 0%,
      rgba(12, 3, 26, 0.3) 48.54%,
      #0c031a 100%
    );
    width: 100%;
    z-index: 2;
    height: 322px;
    @media (max-width: 768px) {
      bottom: -2px;
    }
  `;

  const fetchNewsDetail = useCallback(async () => {
    try {
      const newsLink = getEndPath();
 
      const res = await axios.get(`${NEWS_API_URL}/news/details`, {
        params: {
          newsLink: newsLink,
        },
      });

      const { authorName,backgroundImage,date,heading,content } = res.data;
      const resObject = {
        authorName: authorName ? authorName : null,
        backgroundImage: backgroundImage ? backgroundImage : null,
        date: date ? date : 0,
        heading: heading ? heading : null,
        content: content ? processedContent(content) : null,
      };
      setNewsDetail(resObject);
      setLoading("false");
    } catch (error) {
      console.error(error);
      setLoading("error");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchNewsDetail();
  }, [fetchNewsDetail]);

  return (
    <div className="news-detail-page">
      {loading === "true" ? (
        // <p>loading</p>
        <Loader />
      ) : loading === "false" ? (
        <>
          <div className="container">
            <div className="news-detail-description">
              {/* <div className="flex items-center space-between">
                <Para className="fw-500">
                  Author: <span>{newsDetail.authorName}</span>
                </Para>
                <address>Date: {newsDetail.date}</address>
              </div> */}
              <h1>{newsDetail.heading}</h1>
              <address>{newsDetail.date}</address>
              {/* content */}
              <div dangerouslySetInnerHTML={{ __html: newsDetail.content }} />
            </div>
          </div>
        </>
      ) : (
        <ComponentNotFound />
      )}
    </div>
  );
}

export default NewsDetail;
