import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// import HeroSection from "./hero-section/HeroSection";
// import Slider from "./whats-new-slider/Slider";
// import Experience from "./experience/Experience";
// import CollectionSlider from "./collection-slider/CollectionSlider";
// import VirtuaKolect from "./virtua-kolect/VirtuaKolect";
// import MonsterZone from "./monster-zone/MonsterZone";
// import CribSlider from "./crib-experience-slider/Slider";
// import Founder from "./founder/Founder";
// import VirtuaWeb from "./virtua-web/VirtuaWeb";
// import VirtuaGamesNetwork from "./virtua-games-network/VirtuaGamesNetwork";
// import VirtuaFeature from "./virtua-feature/VirtuaFeature";
// import VirtuaTab from "./virtua-tabs/VirtuaTab";
// import ConnectSection from "./connect-section/Connect";
import PartnerSection from "./partner/Partner";
// import PlayGamesSlider from "./play-games-slider/PlayGamesSlider";
// import CollectReward from "./collect-reward/CollectReward";
import MainBanner from "./main-banner/MainBanner";
// import GameSlider from "./game-slider/GameSlider";
// import WaitingSection from "./waiting-section/Waiting";
// import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
// import { CookiesModal } from "../modal/CookiesModal";
import MetricsGame from "../developers/metrics-games/Metrics";
import VirtuaSdk from "../developers/virtua-sdk/VirtuaSdk";
import LoginFormContainer from "../../containers/LoginFormContainer";
import { useLocation } from "react-router-dom";
const Home = () => {
  const location = useLocation();
  const { state } = location;

  // const { keyCloakUser } = useKeycloakFeatures();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (state?.scrollToJoinUs === true) {
      const targetElement = document.getElementById("join-virtua");
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <Helmet>
      <title>Virtua | Maximize Game Lifetime Value with Virtua Solutions</title>
        <meta
          name="description"
          content="Discover how Virtua empowers game developers, studios, and publishers to enhance player lifetime value through the VGN Platform and Virtua SDK. Explore the Gamers Lounge App, NFT Marketplace, and SDK to create engaging experiences and grow your game"
        />
      </Helmet>

      <div className="relative">
        {/* <CookiesModal/> */}
        {/* <HeroSection /> */}
        {/* <PlayGamesSlider /> */}
        <MainBanner />
        {/* <GameSlider /> */}
        {/* <CollectReward /> */}
        {/* <ConnectSection /> */}
        {/* {keyCloakUser === null && <WaitingSection />} */}
        <MetricsGame />
        {/* <VirtuaSdk /> */}
        <PartnerSection />
        {/* <CribSlider /> */}
        {/* <Slider /> */}
        {/* <Founder /> */}
        {/* <VirtuaGamesNetwork /> */}
        {/* <VirtuaFeature />  */}
        {/* <Experience /> */}
        {/* <VirtuaTab /> */}
        {/* <CollectionSlider /> */}
        {/* <VirtuaKolect /> */}
        {/* <VirtuaWeb /> */}
        {/* <MonsterZone /> */}
        {/* <LoginFormContainer /> */}
      </div>
    </>
  );
};

export default Home;
