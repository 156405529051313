import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Games from "../../views/games/Games";
import Home from "../../views/home/Home";
import LandSalePage from "../../views/landsale-page/LandSalePage";
// import Tvk from "../../views/tvk/Tvk";
import Crib from "../../views/crib/Crib";
import { PrivacyPolicy } from "../../views/contentPages/PrivacyPolicy";
import { UserGuideline } from "../../views/contentPages/UserGuideline";
import { TermsServices } from "../../views/contentPages/TermsServices";
import SketchfabModal from "../../views/sketchfab/Index";
import Header from "../core/header/Header";
import Footer from "../core/footer/Footer";
import { marketplaceRoutes } from "../../utils/marketplace_routes";
import ComponentNotFound from "../../views/component-not-found/ComponentNotFound";
import WebGames from "../../views/web-games/WebGames";
import Metaverse from "../../views/metaverse/MetaverseHomePage";
import VoteTvk from "../../views/vote-tvk/VoteTvk";
import Vanry from "../../views/vanry/Vanry";
import Developers from "../../views/developers/Developers";
import GameDetailPage from "../../views/games/detail-page/GameDetailPage";
// import { KeyCloakContext } from "../../keycloak/KeyCloakProvider";
// import Loader from "../core/loader/loader";
import { CookiesModal } from "../../views/modal/CookiesModal";
// import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
// import { useLedger } from "../../views/custom-hooks/useLegder";
// import keycloak from "../../keycloak/keycloack";
// import { useMyContext } from "../../providers/ProviderAppStatus";
// import { getClaimStatusApi } from "../common/api-calls/Common";
import PasscodeModal from "../../views/modal/SecretPasscode";
import GamePage from "../../views/game-page/GamePage";
import News from "../../views/news/News";
import NewsDetail from "../../views/news-detail/NewsDetail";
import { Helmet } from "react-helmet";
import ProductPage from "../../views/product-page/ProductPage";
export const MainRouter = () => {
  const location = useLocation();
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  let pages = ["/privacy-policy", "/user-guidelines", "/terms-service"];
  const [is_header, setHeader] = useState(true);
  const [showCookieModal, setShowCookieModal] = useState(
    !localStorage.getItem("cookie")
  );
  const [searchParams, setSearchParams] = useSearchParams();
  // const { isKeycloakInit, keyCloakUser } = useKeycloakFeatures();
  // const [show, setShow] = useState(true);
  // const { updateState } = useMyContext();
  const robotsMeta = process.env.REACT_APP_ROBOTS || "noindex,nofollow";
  const toggle = () => {
    localStorage.setItem("cookie", true);
    setShowCookieModal(false);
  };
  // useEffect(function () {
  //   if (location.pathname.includes("sketchfab")) {
  //     setHeader(false);
  //   }

  //   if (pages.includes(location.pathname)) {
  //     if (searchParams.get("platform") === "inapplogin") {
  //       setHeader(false);
  //     }
  //   }

  //   let pathName = location.pathname;
  //   let currentUrl = window.location.href;
  //   marketplaceRoutes.map((marketplaceRoute) => {
  //     if (pathName.includes(marketplaceRoute)) {
  //       setHeader(false);
  //       if (marketplaceRoute === "/marketplace") {
  //         let queryString = currentUrl.split("marketplace")[1];
  //         window.location.href =
  //           process.env.REACT_APP_CURRENT_URL + "/marketplace" + queryString;
  //       } else {
  //         window.location.href = process.env.REACT_APP_CURRENT_URL + pathName;
  //       }
  //     }
  //     // console.log(marketplaceRoute, pathName.includes(marketplaceRoute), pathName)
  //   });
  // }, []);

  // useEffect(() => {
  //   if (keyCloakUser !== null && keyCloakUser !== "undefined") {
  //     const fetchClaimStatus = async () => {
  //       try {
  //         const res = await getClaimStatusApi(keyCloakUser?.accessToken);
  //         updateState(res?.status);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     };

  //     fetchClaimStatus();
  //   }
  // }, [keyCloakUser]);

  // useEffect(() => {
  //   const hasPasscode = Array.isArray(keyCloakUser?.attributes?.has_passcode)
  //     ? keyCloakUser.attributes.has_passcode[0] === "false"
  //     : false;

  //   setShowRegisterModal(hasPasscode ? true : false);
  //   if (hasPasscode === true) {
  //     document.body.classList.add("registration");
  //   }
  // }, [keyCloakUser?.attributes?.has_passcode]);
  

  return (
    <>
      <Helmet>
        <meta name="robots" content={robotsMeta} />
      </Helmet>
      {is_header && <Header />}

      <main id="main">
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/land-sale" element={<LandSalePage />} /> */}
          <Route path="/news" element={<News />} />
          <Route path="/news/:newsLink" element={<NewsDetail />} />
          <Route path="/developers" element={<Developers />} />
          {/* <Route path="/products" element={<VirtualWorld />} /> */}
          <Route path="/products" element={<ProductPage />} />
          <Route path="/games/:gameName" element={<GameDetailPage />} />
          {/* <Route path="/vanry" element={<Vanry />} /> */}
          <Route path="/experience" element={<Crib />} />
          <Route path="/metaverse" element={<Metaverse />} />
          <Route path="/games-vgn" element={<GamePage />} />
          <Route path="/vote" element={<VoteTvk />} />
          {/* <Route path="/experience" element={<Experience />} /> */}
          <Route path="/land-sale" element={<LandSalePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/web2-to-web3" element={<WebGames />} />
          <Route path="/user-guidelines" element={<UserGuideline />} />
          <Route path="/terms-service" element={<TermsServices />} />
          <Route path="/sketchfab/:id" element={<SketchfabModal />} />
          <Route path="*" element={<ComponentNotFound />} />
          {/* <Route path="/tvk" element={<Navigate to="/vanry" />} /> */}
        </Routes>
      </main>
      {showRegisterModal && (
        <PasscodeModal setIsOpenModal={setShowRegisterModal} />
      )}
      {showCookieModal && !location.pathname.includes("privacy-policy") && (
        <CookiesModal hide={toggle} />
      )}
      {is_header && <Footer />}
    </>
  );
};
