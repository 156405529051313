import {
  GeneralsIcon,
  REACT_APP_S3_CLOUDFLARE_IMAGE_URL,
} from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import styled from "styled-components";
import { SHELBY_URL } from "../../enviroment";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

function ShelbyGame() {
  return (
    <>
      <div className="virtual-wrold-game">
        <div className="banner">
          <GradientEffect />
          <img
            className="slide-bg web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}shelby-img.png`}
            alt="shelby"
          />
          <img
            className="slide-bg mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mob-shelby.jpg`}
            alt="shelby"
          />
          <BannerText className="text-holder cardano">
            <SectionHeading>Shelby American</SectionHeading>
            <Para className="item">
              Shelby American is a legendary car maker, responsible for classics
              like the Shelby Cobra. Shelby is working together with Virtua to
              bring their cars into the digital world through a multi-media
              activation. The Shelbyverse is launching in Roblox, thanks to our
              partner Gamefam, where players will be able to race around with
              their Shelby cars. Not only that, we also have partnerships for
              digital collectibles and physical merch so you can show off your
              love for Shelby IRL.
            </Para>
            <div className="buttons w-100">
              <a
                href={SHELBY_URL}
                target="_blank"
                id="shelby_american"
                rel="noreferrer"
                className="btn"
              >
                <span className="icon" style={{ lineHeight: "0" }}>
                  {GeneralsIcon.globeIcon}
                </span>
                Visit Website
              </a>
            </div>
          </BannerText>
        </div>
        {/* <DashboardBanner handleClickGamersLounge={handleClickGamersLounge} /> */}
      </div>
    </>
  );
}

export default ShelbyGame;
