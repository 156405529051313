import styled from "styled-components";

export const Heading = styled.div`
  padding-top: ${(props) => (props.slider ? "0" : "50px")};
  padding-bottom: ${(props) => (props.slider ? "50px" : "50px")};
`;
export const SubTitle = styled.h4`
  font-weight: 700;
  color: #ffffff;
  line-height: ${(props) => (props.slider ? "100%" : "70px")};
  text-align: ${(props) => (props.left ? "left" : "center")};
  font-size: ${(props) =>
    props.hero ? "32px" : `${props.small ? "26px" : "64px"}`};
  margin: 0;
  letter-spacing: -0.03em;
  text-transform: ${(props) => (props.upper ? "capitalize" : "")};

  margin-bottom: ${(props) => (props.gYN ? "8px" : "")};

  @media (max-width: 1300px) {
    font-size: ${(props) => (props.slider ? "46px" : "")};
  }
  @media (max-width: 1180px) {
    text-align: ${(props) => (props.slider ? "center" : "")};
  }
  @media (max-width: 992px) {
    text-align: ${(props) => (props.left ? "center" : "")};
    font-size: ${(props) => (props.mbl ? "18px" : "40px")};
    line-height: ${(props) => (props.slider ? "100%" : "46px")};
  }
  @media (max-width: 600px) {
    text-align: ${(props) => (props.left ? "center" : "")};
    font-size: ${(props) => (props.mbl ? "18px" : "32px")};
    line-height: ${(props) => (props.slider ? "100%" : "36px")};
  }
`;
export const Title = styled.h1`
  font-weight: 800;
  font-size: 68px;
  -webkit-text-stroke: 2px #fff;
  line-height: 100px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: linear-gradient(266.54deg,#FFFFFF 0%, rgba(255, 255, 255, 0.05) 0%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 319px) {
    font-size:72px;
    letter-spacing: -2px;
    line-height: 65px; margin: 0px;
  }
  @media only screen and (min-width: 320px) and (max-width: 600px){ {
    font-size: 46px;
    line-height: 50px;
    margin: 0px;
  }
`;

export const SocialLinks = styled.a`
  gap: 16px;
  @media (max-width: 420px) {
   
  }
`;

export const Para = styled.p`
  font-size: ${(props) => (props.sdkCardDetail ? "16px" : "20px")};
  color: #fff;
  font-weight:${(props) => (props.cardWeight ? "400" : "400")};
  margin: 0;
  margin-bottom: ${(props) => (props.sdkCardDetail ? "20px" : "")};
  line-height: ${(props) => (props.sdkCardDetail ? "20px" : "24px")};
  letter-spacing: 0.2px;
  max-width: ${(props) => (props.maxWidth ? "640px" : "")};
  @media (max-width: 600px) {
    text-align: center;
    font-size: ${(props) => (props.cribSlider ? "14px" : "16px")};
    line-height: ${(props) => (props.sdkCardDetail ? "20px" : "24px")};
  }
`;



export const SectionHeading = styled.h2`
  font-weight: 600;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 267.39%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // font-size: ${(props) => (props.secondaySize ? "80px" : "60px")};
  font-size: clamp(30px, 3.13vw, 60px);
  margin: ${(props) => (props.headingMargin ? "16px 0" : "0")};
  line-height: clamp(34px, 3.16vw, 64px);
  // line-height: ${(props) => (props.secondaySize ? "84px" : "64px")};
  text-transform: uppercase;
  // @media (max-width: 992px) {
  //   font-size: 40px;
  //   line-height: 42px;
  // }
  // @media (max-width: 600px) {
  //   font-size: 30px;
  //   line-height: 34px;
  // }
`;
export const TitleHeading = styled.h3`
  font-weight: 600;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 267.39%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: ${(props) => (props.connectSection ? "40px" : "32px")};
  line-height: ${(props) => (props.connectSection ? "44px" : "36px")};
  margin: 0;
  text-transform: uppercase;
  @media (max-width: 992px) {
    font-size: ${(props) => (props.connectSection ? "90px" : "26px")};
    line-height: ${(props) => (props.connectSection ? "94px" : "32px")};
  }
`;
export const SectionTitle = styled.h3`
  font-weight: 600;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 267.39%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // font-size: 28px;
  font-size: clamp(24px, 1.45vw, 28px);
  margin: 0 0 45px;
  line-height: clamp(28px, 1.5vw, 32px);
  text-transform: uppercase;
  // @media (max-width: 992px) {
  //   font-size: 26px;
  //   line-height: 30px;
  // }
  // @media (max-width: 600px) {
  //   font-size: 24px;
  //   line-height: 28px;
  // }
`;
export const CardTitle = styled.h4`
  font-weight: 600;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 267.39%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // font-size: 24px;
  font-size: clamp(20px, 1.24vw, 24px);
  margin: 0 0 22px;
  line-height: clamp(28px, 1.5vw, 32px);
`;
export const SectionContainer = styled.div`
max-width: 1470px;
margin: 0 auto;
padding: 0 14px;
width: 100%;
`;