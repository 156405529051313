import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

export const hasEmptyField=(obj)=> {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
          return true;
        }
      }
    }
    return false;
  }

export const maxLength=(str,maxlength)=>
{
  if(str.length<maxlength)
  {
    return true;
  }
  return false;
}
export const minLength=(str,minlength)=>
{
  if(str.length>minlength)
  {
    return true;
  }
  return false;
}

export const setPasscodePopupBoolean = (value)=> {
  localStorage.setItem('passcode-popup', JSON.stringify(value))
}

export const getEndPath=()=>{
  const pathname = window.location.pathname;

// Extract the last segment of the path
const endPath = pathname.substring(pathname.lastIndexOf("/") + 1);
return decodeURIComponent(endPath);
}

export const processedContent = (content) => {
  const updatedContent = content
  .replace(
    /\$\{REACT_APP_S3_CLOUDFLARE_IMAGE_URL\}/g, // Match the placeholder
    process.env.REACT_APP_S3_CLOUDFLARE_IMAGE_URL // Replace it with the correct URL
  )
  .replace(/[`{}]/g, ''); // Remove backticks, curly braces, and template literal symbols

  // console.log("Updated content:", updatedContent); // Debugging step
  return updatedContent;
};


