import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import styled from "styled-components";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

function HunterGame() {
  return (
    <>
      <div className="virtual-wrold-game">
        <div className="banner">
          <GradientEffect />
          <img
            className="slide-bg web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}hunter-img.png`}
            alt="hunter"
          />
          <img
            className="slide-bg mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mob-hunter.jpg`}
            alt="hunter"
          />
          <BannerText className="text-holder shelby">
            <SectionHeading>Z Hunters</SectionHeading>
            <address className="developer">Developer: 1MBand</address>
            <Para className="item">
              Zombie Hunter is a mobile game developed by 1MBand. Wanting to
              respect the game which is more focused on getting players into the
              action quick, the teams worked together to focus on a more limited
              set of features, focusing on the action and replayability.
            </Para>
            {/* <div className="buttons w-100">
              <a
                href="https://play.google.com/store/apps/details?id=com.generamobile.zhunter.gp"
                target="_blank"
                rel="noreferrer"
                className="btn"
                id="z_hunter"
              >
                <span className="icon">
                  <img
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}android-dark.svg`}
                    alt="android"
                  />
                </span>
                Google Play
              </a>
            </div> */}
            <Para className="purple">Key features:</Para>
            <ul className="flex items-center gap-60">
              <li>Shared login</li>
              <li>Weekly leaderboard</li>
              <li>Quests</li>
            </ul>
          </BannerText>
        </div>
        {/* <DashboardBanner handleClickGamersLounge={handleClickGamersLounge} /> */}
      </div>
    </>
  );
}

export default HunterGame;
