import { useCallback } from "react";

const usePageNumbers = (currentPage, totalPages) => {
    const generatePageNumbers = useCallback(() => {
        const pageNumbers = [];
      
        // Ensure currentPage and totalPages are numbers
        if (typeof currentPage !== 'number' || typeof totalPages !== 'number') {
          return pageNumbers;
        }
      
        if (totalPages <= 5) {
          // Show all pages if totalPages is 5 or less
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
        } else {
          // Handle ellipses for larger page numbers
          if (currentPage === 1) {
            pageNumbers.push(1, 2, '...', totalPages - 1, totalPages);
          } else if (currentPage === 2) {
            pageNumbers.push(1, 2, 3, '...', totalPages);
          } else if (currentPage >= totalPages - 1) {
            pageNumbers.push(1, '...', totalPages - 2, totalPages - 1, totalPages);
          } else {
            pageNumbers.push(
              1,
              '...',
              currentPage - 1,
              currentPage,
              currentPage + 1,
              '...',
              totalPages
            );
          }
        }
      
        return pageNumbers;
      }, [currentPage, totalPages]);
      

  return generatePageNumbers();
};

export default usePageNumbers;
