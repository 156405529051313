import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import GladiatorGame from "./GladiatorGame";
import HunterGame from "./HunterGame";
import SoccerGame from "./SoccerGame";
import NitroGame from "./NitroGame";
import CardanoGame from "./CardanoGame";
import ShelbyGame from "./ShelbyGame";
import "./Gamepage.scss";

function GamePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Virtua | Revolutionizing Gaming Through Player Connectivity
        </title>
        <meta name="description" content="Discover how Virtua empowers game developers, studios, and publishers to enhance player lifetime value through the VGN Platform and Virtua SDK. Explore the Gamers Lounge App, NFT Marketplace, and SDK to create engaging experiences and grow your game" />
      </Helmet>
      <GladiatorGame />
      <HunterGame />
      <SoccerGame />
      <NitroGame />
      {/* <ShelbyGame /> */}
      <CardanoGame />
    </>
  );
}

export default GamePage;
