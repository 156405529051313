const bgColors = {
  primary: "rgba(146, 71, 255, 0.24)",
  secondary: "#9247FF",
  transparent: "transparent",
  cardano: "#0033AD",
  purple: "#9247ff",
  gray:"rgb(255 255 255 / 30%)",
  gradients: "linear-gradient(90deg, #B26EE4 0%, #F1209B 100%);",
};
const colors = {
  default: "#fff",
};
const borders = {
  white: "#fff",
  bprimary:"#9247ff",
  border0:"transparent",
};

const viewports = {
  smartphone: "360px",
  tablet: "720px",
  desktop: "1280px",
};

const forms = {
  labelColor: colors.light,
  errorColor: colors.danger,
};

const theme = Object.freeze({
  colors,
  bgColors,
  forms,
  viewports,
  borders,
});

export default theme;
