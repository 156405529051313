import "./brandSlider.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { Autoplay, Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";

const BrandSlider = () => {
  return (
    <section className="brand-section">
      <div className="brand-slider">
        <div className="section-holder">
          <Swiper
            modules={[Navigation, Autoplay]}
            loop={true}
            speed={7000}
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
            }}
            initialSlide={0}
            slidesPerView={4.5}
            spaceBetween={45}
            centeredSlides={true}
            breakpoints={{
              320: {
                slidesPerView: 1.6,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                spaceBetween: 20,
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 4.6,
              },
            }}
          >
            <SwiperSlide>
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}vanar-partnerImg.webp`}
                    alt="vanar-partnerImg"
                  />
                </div>
              </div>
              {/* <div className="grid">
                <span className="sp"></span>
                <span className="sp"></span>
                <span className="sp"></span>
                <span className="sp"></span>
                <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}nitro_league.png`} alt="nft" />
              </div> */}
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}viva-partnerImg.webp`}
                    alt="viva-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}bazaa-partnerImg.webp`}
                    alt="bazaa-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}maians-partnerImg.webp`}
                    alt="maians-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}nitro.webp`}
                    alt="nitro-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card cactus">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}big-cactus.webp`}
                    alt="cactus-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card cardano">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}cardano.webp`}
                    alt="cardano-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card new_">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}shelby-partnerImg.webp`}
                    alt="shelby-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card new_">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}1M-partnerImg.webp`}
                    alt="1M-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card new_">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}redvel-partnerImg.webp`}
                    alt="redvel-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card new_">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}gamefam-partnerImg.webp`}
                    alt="gamefam-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card roblox">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}roblox-partnerImg.webp`}
                    alt="roblox-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card new_">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}scuti-partnerImg.webp`}
                    alt="scuti-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card new_">
                <div className="card-img">
                  <img
                    className="img"
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}merchmake-partnerImg.webp`}
                    alt="merchmake-partnerImg"
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default BrandSlider;
