import { useCallback, useEffect, useState } from "react";
import NewsListingItem from "../../components/items/NewsListingItem";
import { SectionHeading } from "../../components/styledComponent";
import "./news.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { NEWS_API_URL } from "../../enviroment";
import { update } from "firebase/database";
import PaginationCustom from "../reuseables/PaginationCustom";
import usePageNumbers from "../custom-hooks/usePageNumbers";
import Loader from "../../components/core/loader/loader";
import { Helmet } from "react-helmet";

function News() {
  const navigate = useNavigate();
  const [news, setNews] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [category, setCategory] = useState("Updates");
  const [count, setCount] = useState(0);
  const pageSize = 10;
  const next = currentPage * pageSize;
  const categories = ["Updates", "Events", "Community", "Marketplace"];
  // Generate an array of page numbers
  const pageNumbers = usePageNumbers(currentPage, totalPages);

  // Use useCallback to memoize the fetchNewsData function
  const fetchNewsData = useCallback(
    async (categoryText = "Updates") => {
      const filter = [
        {
          key: "active",
          op: "==",
          value: true,
        },
        {
          key: "category",
          op: "array-contains",
          value: categoryText.toLowerCase(),
        },
      ];
      try {
        const res = await axios.get(`${NEWS_API_URL}/news`, {
          params: {
            pageSize: pageSize,
            pageNo: currentPage,
            filters: filter,
          },
        });
        const { docs, count } = res.data;

        // Process each document in the docs array
        const newsObject = docs.map((doc) => ({
          heading: doc["heading"] ? doc["heading"] : null,
          subHeading: doc["sub-heading"] ? doc["sub-heading"] : null,
          authorName: doc["author-name"] ? doc["author-name"] : null,
          date: doc["date"] ? doc["date"] : 0,
          newsLink: doc["news-link"] ? doc["news-link"] : null,
          imgSrc: doc["imgSrc"] ? doc["imgSrc"] : null,
        }));

        setNews(newsObject);
        setLoading(false);
        setCount(count);

        if (count) {
          const totalPages = Math.round(count / pageSize);
          setTotalPages(totalPages);
        } else {
          // console.log(object);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [pageSize, currentPage]
  );

  useEffect(() => {
    fetchNewsData();
  }, [fetchNewsData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCategory = (e) => {
    e.stopPropagation();
    const categoryText = e.target.textContent;
    if (categories.includes(categoryText)) {
      setLoading(true);
      setCategory(categoryText);
      fetchNewsData(categoryText);
    }
    return;
  };

  // Handle page click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle Next button click
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // Handle Previous button click
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>Stay Updated on the Latest Virtua Trends and News</title>
        <meta name="description" content="Discover how Virtua empowers game developers, studios, and publishers to enhance player lifetime value through the VGN Platform and Virtua SDK. Explore the Gamers Lounge App, NFT Marketplace, and SDK to create engaging experiences and grow your game" />
      </Helmet>
      <div className="news-page">
        <div className="container">
          <div className="flex items-center flex-wrap gap-20 space-between">
            <SectionHeading>News</SectionHeading>
            <ul
              className="flex items-center"
              onClick={(e) => handleCategory(e)}
            >
              <li className={category === "Updates" ? "active" : ""}>
                Updates
              </li>
              <li className={category === "Events" ? "active" : ""}>Events</li>
              <li className={category === "Community" ? "active" : ""}>
                Community
              </li>
              <li className={category === "Marketplace" ? "active" : ""}>
                Marketplace
              </li>
            </ul>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="news-holder">
                {news.length === 0 ? (
                  <div className="no_news">No news updates at the moment</div>
                ) : (
                  news.length > 0 &&
                  news.map((item) => (
                    <NewsListingItem item={item} navigate={navigate} />
                  ))
                )}
              </div>
              {/* {pageNumbers.length > 0 && (
                <>
                  <PaginationCustom
                    currentPage={currentPage}
                    handlePreviousClick={handlePreviousClick}
                    handleNextClick={handleNextClick}
                    handlePageClick={handlePageClick}
                    pageNumbers={pageNumbers}
                    totalPages={totalPages}
                    next={next}
                    count={count}
                  />
                </>
              )} */}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default News;
