import React, { useEffect, useState, useRef, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  hideBodyOverflow,
  REACT_APP_S3_CLOUDFLARE_IMAGE_URL,
  showBodyOverflow,
} from "../../../constants/constant";
import { MARKETPLACE_URL } from "../../../enviroment/index.js";
import "./header.scss";
// import { WhyModalPresentation } from "../../../views/modal/WhyModalPresentation.js";
import { useModal } from "../../../views/custom-hooks/useModal.js";
// import useKeycloakFeatures from "../../../keycloak/useKeycloakFeatures.ts";
// import ProfileDropDown from "../../items/ProfileDropDown";
// import { NotificationModal } from "../../../views/modal/NotificationModal";
// import { useLedger } from "../../../views/custom-hooks/useLegder";
import Button from "../../generic/button";
import { getMultiFactorResolver } from "firebase/auth";
const HeaderLogo = styled.img`
  max-width: 120px;
  width: 100%;
  min-width: 120px;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    max-width: 110px;
    min-width: 110px;
  }
`;
const HeaderContainer = styled.div`
  /* max-width: 1920px; */
  max-width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  @media (max-width: 600px) {
    padding: 0 15px;
  }
`;

const Header = () => {
  // const {
  //   doLogin,
  //   doLogout,
  //   doSignUp,
  //   keyCloakUser,
  //   isLoging,
  //   isKeycloakInit,
  // } = useKeycloakFeatures();
  const [toggleMenu, setToggleMenu] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [dropDownState, setDropDownState] = useState(false);
  // const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();
  // const { ledgerData } = useLedger(keyCloakUser);
  const { isShowing, toggle } = useModal();
  const { pathname } = useLocation();
  const modalRef = useRef(null);
  const buttonRef = useRef(null);
  const location = useLocation();
  const targetElement = document.getElementById("join-virtua");

  // useEffect(() => {

  //   if (scrollToJoinUs && window.location.pathname === "/" && targetElement) {
  //     const targetElement = document.getElementById("join-virtua");
  //     if (targetElement) {
  //       targetElement.scrollIntoView({ behavior: "smooth" });
  //     }
  //     // setScrollToJoinUs(false);
  //     // localStorage.setItem("scrollToJoinUs", false); // Reset the state to avoid repeated scrolling
  //   }
  // }, [scrollToJoinUs,targetElement]); // Only include state that triggers the effect

  useEffect(() => {
    if (!location?.pathname?.includes("games")) {
      localStorage?.removeItem("selectedCategory");
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (showNotifications) {
  //     hideBodyOverflow();
  //   }
  // }, [showNotifications]);

  const stickyHeader = () => {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    if (window.pageYOffset > sticky) {
      header.classList.add("active");
    } else {
      header.classList.remove("active");
    }
  };
  const addBodyClass = () => {
    const body = document.getElementById("body");
    if (
      ["/user-guidelines", "/privacy-policy", "/terms-service"].includes(
        pathname
      )
    ) {
      body.classList.add("contentPages");
    } else {
      if (body.classList.contains("contentPages")) {
        body.classList.remove("contentPages");
      }
    }
  };

  useEffect(() => {
    addBodyClass();
  }, [pathname]);
  
  useEffect(() => {
    window.scrollTo(0, 0);

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
    window.addEventListener("scroll", stickyHeader);
  }, []);
  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
    if (toggleMenu) {
      hideBodyOverflow();
    } else {
      showBodyOverflow();
    }
  };
  const toggleNavLogo = () => {
    setToggleMenu(true);
    if (!toggleMenu) {
      showBodyOverflow();
    }
  };
  // const handleProfileDropDown = (event) => {
  //   event.stopImmediatePropagation();
  //   setDropDownState(!dropDownState);
  // };

  const handleOutsideClick = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropDownState(false);
    }
  };
  useEffect(() => {
    if (dropDownState) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropDownState]);

  // const handleShowNofitications = () => {
  //   setShowNotifications(!showNotifications);
  // };

  const handleJoinUsClick = () => {
    if (screenWidth <= 1250) {
      toggleNav();
    }
    const targetElement = document.getElementById("join-virtua");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/", { state: { scrollToJoinUs: true } });
    }
  };

  return (
    <>
      <header id="header" className="fixed">
        <HeaderContainer>
          <nav className="navigation">
            <a href="/" id="virtua_logo">
              {screenWidth > 992 ? (
                <HeaderLogo
                  src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}logo.svg`}
                />
              ) : (
                <HeaderLogo
                  onClick={toggleNavLogo}
                  src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}white-logo.svg`}
                />
              )}
            </a>
            <div className="user_image_holder">
              {toggleMenu || screenWidth > 992 ? (
                <>
                  <div className="navigation-menu top">
                    <ul>
                      <li className={pathname.includes("products") && "active"}>
                        <Link id="header_products" to="/products">
                          Products
                        </Link>
                      </li>
                      <li className={pathname.includes("games-vgn") && "active"}>
                        <Link id="header_games" to="/games-vgn">
                          Games
                        </Link>
                      </li>
                      <li className={pathname.includes("news") && "active"}>
                        <Link id="header_news" to="/news">
                          News
                        </Link>
                      </li>
                      {/* <li>
                        <Button
                          id="join_us"
                          bprimary
                          upper
                          primary
                          onClick={() => handleJoinUsClick()}
                        >
                          Join Us
                        </Button>
                      </li> */}
                      {/* <li
                        className={pathname.includes("developers") && "active"}
                      >
                        <Link to="/developers">Developers</Link>
                      </li> */}
                      {/* <li
                        className={
                          pathname.includes("virtual-world") && "active"
                        }
                      >
                        <Link to="/virtual-world">Virtual Worlds</Link>
                      </li> */}
                      {/* <li>
                        <a href={MARKETPLACE_URL}>Marketplace</a>
                      </li> */}
                      {/* <li className={pathname.includes("vanry") && "active"}>
                      <Link to="/vanry">VANRY</Link>
                    </li> */}
                      {/* <li className={pathname.includes("vote") && "active"}>
                      <Link to="/vote">Vote TVK</Link>
                    </li> */}
                    </ul>
                  </div>
                  {/* {keyCloakUser === null ? (
                    <div className="navigation-menu">
                      <ul className="social-icons">
                        <li className="signIn" onClick={toggle}>
                          <a>Why?</a>
                        </li>
                        {isKeycloakInit && (
                          <>
                            <li className="signIn" onClick={doLogin}>
                              <a>Sign In</a>
                            </li>
                            <li className="blog-text" onClick={doSignUp}>
                              <a>SIGN UP</a>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  ) : (
                    <div className="relative">
                      <div className="signIn">
                        <div
                          className="user-profile"
                          ref={buttonRef}
                          onClick={() => setDropDownState(!dropDownState)}
                        >
                          <div className="user-profile-img">
                            <img
                              src={
                                keyCloakUser?.attributes?.profile_image
                                  ? keyCloakUser?.attributes?.profile_image[0]
                                  : `${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}virtua-user-dp.jpg`
                              }
                              alt="user-dp"
                            />
                          </div>
                          <div className="user-profile-text">
                            <div className="user-profile-text-left">
                              <p>{keyCloakUser?.username}</p>
                              <p>
                                <img
                                  src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}xp.svg`}
                                  alt="xp"
                                />
                                <span className="point">
                                  {ledgerData?.xp?.toLocaleString() ?? 0}
                                </span>
                              </p>
                            </div>
                            <div className="user-profile-text-right">
                              <div className="arrow">                          
                                  <img
                                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}arrow.svg`}
                                    alt="arrow"
                                  
                                  />                           
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {dropDownState && (
                        <div ref={modalRef}>
                          <ProfileDropDown
                            signOut={doLogout}
                            handleOutsideClick={handleOutsideClick}
                            handleShowNofitications={handleShowNofitications}
                            xp={ledgerData?.xp}
                          />
                        </div>
                      )}
                    </div>
                  )} */}
                </>
              ) : (
                ""
              )}
              <div
                className={
                  toggleMenu
                    ? "hamburger relative"
                    : "hamburger relative change"
                }
                onClick={toggleNav}
              >
                <div className="bar1" />
                <div className="bar2" />
                <div className="bar3" />
              </div>
            </div>
          </nav>
        </HeaderContainer>
      </header>
      {toggleMenu ? (
        ""
      ) : (
        <div className={!toggleMenu ? "mobile-navigation" : ""}>
          <div className="link-navigation">
            <ul>
              {/* <li>
                <Link onClick={toggleNav} to="/metaverse">Metaverse</Link>
              </li>
              <li>
                <a href={ISLAND_URL} onClick={toggleNav}>Land Sale</a>
              </li> */}
              {/* {keyCloakUser === null ? (
                <>
                  <li className="register-btn mb-15" onClick={doSignUp}>
                    <span>sign up</span>
                  </li>
                  <li className="signIn" onClick={doLogin}>
                    <span>Sign In</span>
                  </li>
                  <li className="signIn" onClick={toggle}>
                    <span>Why?</span>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a
                      href={DASHBOARD}
                      target="_blank"
                      className="text-white"
                      rel="noreferrer"
                    >
                      <div className="register-btn">
                        <span>Dashboard</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <span>Lounge</span>
                  </li>
                  <li>
                    <span>Message center</span>
                  </li>
                </>
              )} */}
              <li className={pathname.includes("products") && "active"}>
                <Link id="header_products" to="/products" onClick={toggleNav}>
                  Products
                </Link>
              </li>
              <li className={pathname.includes("games-vgn") && "active"}>
                <Link id="header_games" to="/games-vgn" onClick={toggleNav}>
                  Games
                </Link>
              </li>
              <li className={pathname.includes("news") && "active"}>
                <Link id="header_news" to="/news" onClick={toggleNav}>
                  News
                </Link>
              </li>
              {/* <li className={pathname.includes("developers") && "active"}>
                <Link to="/developers" onClick={toggleNav}>
                  Developers
                </Link>
              </li> */}
              {/* <li>
                <Button
                  id="Join_Us"
                  bprimary
                  upper
                  primary
                  onClick={() => handleJoinUsClick()}
                >
                  Join Us
                </Button>
              </li> */}
              {/* <li>
                <a href={MARKETPLACE_URL} onClick={toggleNav}>
                  Marketplace
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      )}
      {/* {isShowing && <WhyModalPresentation hide={toggle} />}
      {showNotifications && (
        <NotificationModal hideModal={handleShowNofitications} />
      )} */}
    </>
  );
};

export default memo(Header);
