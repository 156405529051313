import {
  GeneralsIcon,
  REACT_APP_S3_CLOUDFLARE_IMAGE_URL,
} from "../../constants/constant";
import { BannerText } from "../home/hero-section/HeroSectionStyled";
import { Para, SectionHeading } from "../../components/styledComponent";
import styled from "styled-components";

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(12, 3, 26, 0) 0%,
    rgba(12, 3, 26, 0.3) 48.54%,
    #0c031a 100%
  );
  width: 100%;
  z-index: 2;
  height: 100%;
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 940px) {
    display: none;
  }
`;

function CardanoGame() {
  return (
    <>
      <div className="virtual-wrold-game">
        <div className="banner">
          <GradientEffect />
          <img
            className="slide-bg web"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}cardano-img.png`}
            alt="cardano"
          />
          <img
            className="slide-bg mob"
            src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}mob-cardano.jpg`}
            alt="cardano"
          />
          <BannerText className="text-holder shelby">
            <SectionHeading>Cardano Island</SectionHeading>
            <Para className="item">
              The Cardano Island provides an open metaverse space for all
              Cardano Landsale Plot Owners. A space that is truly their own,
              where they can utilize their assets and explore the wide world of
              Cardano Island, interact with other players and have their own
              Maians to personify their existence in the metaverse.
            </Para>
            <div className="buttons w-100">
              <a id="cardano_island" rel="noreferrer" className="btn pointer_event_none">
                <span className="icon" style={{ lineHeight: "0" }}>
                  {GeneralsIcon.globeIcon}
                </span>
                Coming Soon
              </a>
            </div>
          </BannerText>
        </div>
        {/* <DashboardBanner handleClickGamersLounge={handleClickGamersLounge} /> */}
      </div>
    </>
  );
}

export default CardanoGame;
